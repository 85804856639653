@import url("https://fonts.googleapis.com/css2?family=ZCOOL+XiaoWei&display=swap");
@import "./varibles.scss";
@import "./breakpoints.scss";
@import "./components/header.scss";
@import "./components/banner.scss";
@import "./components/cases.scss";
@import "./components/navigation.scss";
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #000;
}
body,
html {
  a {
    text-decoration: none;
  }
  visibility: hidden;
  user-select: none;
  background: $white;
  overscroll-behavior: none;
  margin: 0;
  font-family: "ZCOOL XiaoWei", -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;
  height: 100%;
  @include media("<=1560px") {
    width: 1280px;
    min-width: 1280px;
  }
  @include media("<=desktop") {
    width: 1080px;
    min-width: 1080px;
  }
  @include media("<=1080px") {
    width: 100%;
    min-width: 100%;
  }
  .row {
    display: flex;
    height: 100%;
    padding: 0 32px;
    @include media("<=phone") {
      padding: 0 16px;
    }
  }
  .v-center {
    align-items: center;
  }
  .space-between {
    justify-content: space-between;
  }
}

.container-fluid {
  width: 100%;
  height: 100%;
  .row {
    display: flex;
    height: 100%;
  }
}

// intro overlay
.intro-overlay {
  .top {
    height: 50vh;
    height: calc(var(--vh, 1vh) * 50);
    position: absolute;
    width: 100%;
    z-index: 8;
    .overlay-top {
      position: absolute;
      height: 100%;
      width: 25vw;
      background: $black;
      bottom: 0;
      left: 0;
      right: 0;
      @include media("<=tablet") {
        width: 100vw;
      }
      &:nth-child(2) {
        left: 25%;
        @include media("<=tablet") {
          display: none;
        }
      }
      &:nth-child(3) {
        left: 50%;
        @include media("<=tablet") {
          display: none;
        }
      }
      &:nth-child(4) {
        left: 75%;
        @include media("<=tablet") {
          display: none;
        }
      }
    }
  }
  .bottom {
    height: 50vh;
    height: calc(var(--vh, 1vh) * 50);
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 8;
    .overlay-bottom {
      position: absolute;
      height: 100%;
      width: 50vw;
      background: $gold;
      bottom: 0;
      right: 50%;
      @include media("<=tablet") {
        right: 0;
        width: 100vw;
      }
      &:nth-child(2) {
        right: 0;
        @include media("<=tablet") {
          width: 100vw;
          top: 100%;
          right: 0;
        }
      }
    }
  }
}

.App {
  z-index: 2;
  transform: translateY(0);
  position: relative;
}

.page {
  height: 100vh;
  width: 100%;
  background: $white;
  .row {
    display: flex;
    align-items: center;
  }
}

.contents {
  background-repeat: no-repeat;
  background-size: 100%;
  background-size: cover;
}

.synopsis {
  background-image: url("../assets/synopsis.jpg");
  background-position: left bottom;
  .content {
    background-color: rgba(238, 237, 233, 0.9);
    position: absolute; /*or fixed*/
    right: 75px;
    @include media("<=tablet") {
      position: relative;
      width: 100%;
      right: 0;
    }
  }
}

.dream_yoga {
  background-image: url("../assets/dream_yoga.jpg");
  background-position: left top;
  .content {
    background-color: rgba(249, 193, 192, 0.7);
    position: absolute; /*or fixed*/
    right: 75px;
    width: 30%;

    @include media("<=desktop") {
      position: relative;
      width: 100%;
      right: 0;
    }
    @include media("<=tablet") {
      position: relative;
      width: 100%;
      right: 0;
    }
  }
}

.director {
  background-image: url("../assets/director.jpg");
  background-position: right bottom;
  .content {
    background-color: rgba(186, 220, 250, 0.6);
  }
}

.casting {
  background-image: url("../assets/casting.jpg");
  background-position: right bottom;
  .content {
    text-align: center;
    background-color: rgba(101, 160, 124, 0.5);
    margin-left: 250px;
    width: 30%;

    @include media("<=tablet") {
      margin-left: 0;
      width: 60%;
    }

    @include media("<=phone") {
      width: 100%;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  background-position: left top;
  padding: 50px;
  border-radius: 50px;

  @include media("<=tablet") {
    position: relative;
    width: 100%;
    right: 0;
  }
}
