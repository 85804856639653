@keyframes headerReveal {
  0% {
    top: -4em;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.header {
  position: fixed;
  width: 100%;
  height: 100px;
  z-index: 4;
  animation: headerReveal 1.3s;
  @include media("<=phone") {
    height: 90px;
  }
  .logo {
    strong {
      padding: 5px 10px;
      background-color: $black;
      color: $white;
    }

    &:hover {
      strong {
        background-color: $gold;
        color: $black;
      }
    }

    a {
      font-size: 1.2rem;
      color: $white;
      text-decoration: none;
      font-weight: 700;
    }
  }

  .nav-list {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background: linear-gradient($yellow, 90%, $gold);
    border-radius: 5px 5px 25px 25px;
    @include media("<=tablet") {
      display: none;
    }
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      padding: 10px;
      a {
        padding: 7px;
        text-decoration: none;
        color: $black;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 2px;
          display: block;
          margin-top: 5px;
          right: 0;
          background: $black;
          transition: 0.4s ease;
        }
        &:hover {
          color: $black;
          &:after {
            width: 100%;
            left: 0;
            background: $orange;
          }
        }
      }
    }
  }

  .nav-toggle {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media("<=tablet") {
      margin-right: 15px;
    }
    @include media("<=phone") {
      width: 20px;
      margin-right: 10px;
    }

    .hamburger-menu {
      width: 25px;
      span {
        text-align: center;
        margin-bottom: 0.3rem;
        background: $white;
        height: 1px;
        width: 100%;
        display: block;
      }
    }
    .hamburger-menu-close {
      position: absolute;
      display: none;

      svg {
        @include media("<=tablet") {
          width: 56px;
        }
        @include media("<=phone") {
          width: 48px;
        }
      }
    }
  }
}
